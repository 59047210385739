export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@views/Dashboard.vue'),
                meta: {
                  title: 'Dashboard',
                  authRequired: true,
                }
            },
            {
                path: '/account',
                name: 'account',
                component: () => import('@views/account/UserEdit.vue'),
                meta: {
                  title: 'Profile',
                  authRequired: true,
                },
            },
            {
                path: '/orders',
                name: 'order',
                component: () => import('@views/orders/OrderList.vue'),
                meta: {
                  title: "Orders List",
                  authRequired: true,
                },
            },
            {
              path: '/orders/:orderId(\\d+)',
              name: 'order-show',
              component: () => import('@views/orders/OrderShow.vue'),
              meta: {
                title: "View order",
                authRequired: true,
              },
            },
            {
              path: '/notifications',
              name: 'notifications',
              component: () => import('@views/notifications/NotificationList.vue'),
              meta: {
                title: "Notifications",
                authRequired: true,
              }
            },
            {
              path: '/chats',
              name: 'chats',
              component: () => import('@views/chats/Chats.vue'),
              meta: {
                title: "Chats",
                authRequired: true,
              }
            },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '',
        component: () => import('@/layouts/FullPage.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
              guestRequired: true,
              title: "Login"
            }
          },
          {
              path: '/error-404',
              name: 'error-404',
              component: () => import('@/views/pages/Error404.vue'),
              meta: {
                title: "404 - Page not Found"
              }
          },
          {
              path: '/error-500',
              name: 'error-500',
              component: () => import('@/views/pages/Error500.vue'),
              meta: {
                title: "500 - Server error"
              }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '*',
        redirect: '/error-404'
    },

]